<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner" >
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>Service</h2>
      <div class="intro-text">
        You are here:  HomePages / About
      </div>
    </div>
  </div>
  <!-- service -->
  <div class="service columns inner">
    <div class="center-header" >
      <!-- <h3>{{pageData[1].title}}</h3>
      <span>{{pageData[1].contentManagement.description}}</span> -->
    </div>
    <div class="service-box dis-flex">
      <!-- 导航 -->
      <div class="left">
        <div class="menu">
          <el-menu
            :default-active="memuActive"
            class="el-menu-vertical-demo">
            <el-menu-item :index="index.toString()" v-for="(item,index) in pageData" :key="item.id" class="pos-rl" @click="changeTab(item)">
              <p slot="title">{{item.name}}</p>
              <i class="arrow"></i>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="enquiry">
          <div class="enquiry-box">
            <!-- <h3>Ready To GEt Expert</h3> -->
            <el-button class="enquiry-btn">Enquiry</el-button>
          </div>
        </div>
      </div>
      <div class="right" v-if="mainContent.contentManagement">
        <div class="title">{{mainContent.name}}</div>
        <div class="content" v-html="mainContent.contentManagement.content">
        </div>
        <el-collapse v-model="collapseActive" accordion class="collapse">
          <el-collapse-item :title="item.name" :name="index+1+''" v-for="(item,index) in mainList" :key="item.id">
            <div class="text" v-if="item.contentManagement" v-html="item.contentManagement.content"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      pageData:[],
      bannerData:{},
      parentData:{},
      mainContent:{},//右侧主要内容
      mainList:{},//右侧手风琴内容
      collapseActive:'1',
      memuActive:'1'
    }
  },
  watch: {
    $route: {
			handler(newVal, oldVal) {
				if(newVal){
          console.log(newVal);
          this.parentData = newVal.query
          this.changeTab(this.parentData)
          this.memuActive = this.parentData.index
          this.getData(this.parentData.parentId)
          // setTimeout(()=>{
          // },100)
				}
			},
			deep: true
		}
  },
  methods: {
    getData(id){
      if(this.parentData.id){
        post("/publicController/publicData",{id:id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business
                // 防止刷新后内容空白
                this.pageData.map(ele=>{
                  if(ele.id==this.parentData.id) this.changeTab(ele)
                })
                this.bannerData=res.data.homeIntroduction[0]||{}
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },
    changeTab(row){
      console.log(row);
      this.mainContent=row
      this.mainList=row.children
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.parentData = this.$route.query
    console.log(this.parentData);
    this.memuActive = this.parentData.index
    setTimeout(()=>{
      this.getData(this.parentData.parentId)
    },200)
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}

// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}
.service{
  .service-box{
    margin-top: 80px;
  }
  .left{
    width:34%;
    .menu{
      width:515px;
      background-color: #F4F6F9;
      border-radius: 20px;
      padding:40px;
      box-sizing: border-box;
    }
    .enquiry{
      box-sizing: border-box;
      background-image: url('../../static/enquiry.png');
      background-repeat: no-repeat;
      width:515px;
      height: 635px;
      padding:40px;
      margin-top: 40px;
      .enquiry-box{
        width: 100%;
        margin-top: 120px;
      }
      h3{
        font-size: 36px;
        line-height: 1.5;
      }
      .enquiry-btn{
        background-color: #0D63DC;
        color:#fff;
        width:155px;
        height: 60px;
        font-weight: bold;
        font-size: 18px;
      }
    }


  }
  .right{
    flex: 1;
    padding:0 40px;
    overflow: hidden;
    box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
    border-radius: 20px;
    .title{
      font-size: 22px;
      font-weight: bold;
      line-height: 70px;
    }
    .content{
      line-height: 50px;
      font-size: 18px;
      min-height: 300px;
      margin-bottom: 20px;
      p{
        margin-bottom: 30px;
      }
    }
  }
}
::v-deep .el-menu{
  background-color: transparent;
  border-right:none;
  .el-menu-item{
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 20px;
    height: 80px;
    line-height: 80px;
    font-weight: bold;
    font-size: 22px;
    overflow: hidden;
  p{
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: inline-block;
    line-height: 1.2;
    width: 90%;
  }
  }
  .el-menu-item.is-active{
    color:#fff;
    background-color: #2590EB;
  }
  .arrow{
    display: block;
    position: absolute;
    border: 10px solid;
    border-color: transparent transparent transparent #4483E3 ;
    width: 0;
    height: 0;
    right: 10px;
    top:30px;
  }
  .el-menu-item.is-active .arrow{
    border-color: transparent transparent transparent #fff ;
  }
}
::v-deep .el-collapse{
  border:none;
  .el-collapse-item{
    margin-bottom: 20px;
  }
  .el-collapse-item__header{
    font-weight: bold;
    padding:0 30px;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    background-color: #F4F6F9;
    font-size: 18px;
    border-bottom:none;
  }
  .el-collapse-item__header.is-active{
    background-color: #2490EB;
    border-radius: 10px 10px 0 0;
    color:#fff
  }
  .el-collapse-item__wrap{
    border-bottom: none;
  }
  .el-collapse-item__content{
    padding:20px;
    border:1px solid #E9E9E9;
    border-radius: 0 0 10px 10px ;
  }
}
</style>
